import React, { useEffect } from 'react';
import { graphql, Link } from "gatsby"

import { QueryFragments } from "../GraphQl/queryFragments" // eslint-disable-line

// Hooks, Querys & State
import { useAppState } from '../state/appState';

import { PageMeta } from '../components/pageMeta';

import { OneOffForm } from '../components/forms/oneOffForm';
import Seo from '../components/seo';

const SelectEnquiry = () => {

    const { toggleHeaderLight, setHideFooter } = useAppState();

    useEffect(() => {
        toggleHeaderLight(true);
        setHideFooter(true);
    }, [toggleHeaderLight, setHideFooter]);

    return (
        <>
            <Seo title="One-off resident visa" />
            <div className="w-full px-gutter bg-brand text-white min-h-screen mt-8">
                <h1 className="font-display text-3xl xl:text-4xl xl:text-5xl mb-24 pt-28 xl:pt-32 xl:pt-48">
                    Please select a visa enquiry type
                </h1>

                {/* Select Form */}
                <div className="flex flex-col md:flex-row gap-x-12 gap-y-12 text-center">
                    <Link to="/one-off-resident-visa-enquiry" className="min-h-card-m xl:min-h-card hover:bg-white hover:bg-opacity-30 transition ease-in-out duration-300 w-full flex flex-col justify-center items-center p-8 xl:p-16 border border-white rounded-3xl">
                        <h2 className="text-2xl xl:text-5xl font-display xl:leading-card-text min-h-card-text flex items-center justify-center">FREE<br />One-Off Residency<br /> Pre-Qualification</h2>
                        <span className="pb-2 mt-5 border-b-2 border-white flex mt-auto font-display">Enquire now</span>
                    </Link>
                    <Link to="/enquiry" className="min-h-card-m xl:min-h-card hover:bg-white hover:bg-opacity-30 transition ease-in-out duration-300 w-full flex flex-col justify-center items-center p-8 xl:p-16 border border-white rounded-3xl">
                        <h2 className="text-2xl xl:text-5xl font-display xl:leading-card-text min-h-card-text flex items-center justify-center">General Enquiry</h2>
                        <span className="pb-2 mt-5 border-b-2 border-white flex mt-auto font-display">Enquire now</span>
                    </Link>
                </div>


                <div className="w-full border-t border-white py-12 mt-24 xl:mt-60 text-white text-center text-sm flex flex-col xl:flex-row justify-between gap-y-6">
                    <a
                        href="https://www.infrawork.co.nz/"
                        target="_blank"
                        rel="noreferrer"
                        className="font-bold"
                    >
                        A part of the Infrawork Group
                    </a>
                    <div className="flex flex-col gap-6 xl:flex-row">
                        <span>© Visahub 2021. All rights reserved.</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelectEnquiry;
