import React from 'react';
import { motion } from 'framer-motion';
import { useLocation } from '@reach/router';
import { Formik, Form, Field, ErrorMessage } from 'formik';

const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan (Province of China)',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];

const yesNoUnsure = ['YES', 'NO', 'UNSURE'];

const yesNo = ['YES', 'NO'];

const residencyTypes = ['Single', 'Family'];

const visas = [
  'Critical Purpose Visitor Visa',
  'Essential Skills Work Visa',
  'Long Term Skill Shortage List Work Visa',
  'Migrant Exploitation Protection Work Visa',
  'Post Study Work Visa',
  'Religious Worker Work Visa',
  'Silver Fern Practical Experience Work Visa',
  'Skilled Migrant Category Job Search Work Visa',
  'South Island Contribution Work Visa',
  'Talent (Accredited Employer) Work Visa',
  'Talent (Arts, Culture, Sports) Work Visa',
  'Trafficking Victim Work Visa',
  'Work Visa granted under Section 61',
  'Victims of Family Violence Work Visa',
  'Other',
];

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const OneOffForm = () => {
  const { pathname } = useLocation();

  const inputClasses =
    'appearance-none rounded-none bg-brand border-b w-full border-current py-3 lg:text-lg placeholder-white placeholder-opacity-30 lg:mb-6 2xl:mb-8';

  const selectClasses =
    'form-select rounded-none bg-brand border-b w-full border-current py-3 lg:text-lg placeholder-white placeholder-opacity-30 lg:mb-6 2xl:mb-8';

  const labelClasses = 'tracking-wide font-display mb-2';

  const errorClasses = 'tracking-wide text-sm my-2';

  return (
    <div>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          company: '',

          // NEW FIELDS
          currentCity: '',
          scarce: '',
          positionTitle: '',
          payRate: '',
          arrivalDate: '',
          minimumTime: '',
          visaHeld: '',
          passportNationality: '',
          residencyType: '',
          contactMe: '',
          visaExpiry: '',
          notes: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstName) {
            errors.firstName = 'First Name is Required';
          }
          if (!values.lastName) {
            errors.lastName = 'Last Name is Required';
          }

          if (!values.email) {
            errors.email = 'Email address is Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }
          if (!values.phone) {
            errors.phone = 'Field is Required';
          }
          if (!values.company) {
            errors.company = 'Field is Required';
          }
          if (!values.phone) {
            errors.phone = 'Field is Required';
          }

          // FOR NEW FIELDS
          if (!values.currentCity) {
            errors.currentCity = 'Field is Required';
          }
          if (!values.scarce) {
            errors.scarce = 'Field is Required';
          }
          if (!values.positionTitle) {
            errors.positionTitle = 'Field is Required';
          }
          if (!values.payRate) {
            errors.payRate = 'Field is Required';
          }
          if (!values.arrivalDate) {
            errors.arrivalDate = 'Field is Required';
          }
          if (!values.minimumTime) {
            errors.minimumTime = 'Field is Required';
          }
          if (!values.visaHeld) {
            errors.visaHeld = 'Field is Required';
          }
          if (!values.passportNationality) {
            errors.passportNationality = 'Field is Required';
          }
          if (!values.residencyType) {
            errors.residencyType = 'Field is Required';
          }
          if (!values.contactMe) {
            errors.contactMe = 'Field is Required';
          }
          if (!values.visaExpiry) {
            errors.visaExpiry = 'Field is Required';
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          fetch(pathname || '/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': `one-off-form`,
              form_name: 'one-off-form',
              ...values,
            }),
          })
            .then(() => {
              setSubmitting(false);
              setStatus('success');
            })
            .catch((error) => alert(error));
        }}
      >
        {({
          onChange,
          isSubmitting,
          status,
          values,
          /* and other goodies */
        }) => (
          <Form
            className="w-full relative"
            name="one-off-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            subject="Website Enquiry"
          >
            {/* Hidden Fields for netlify */}
            <input type="hidden" name="form-name" value="one-off-form" />
            <p hidden>
              <label>
                <input name="bot-field" onChange={onChange} />
              </label>
            </p>
            <>
              <div
                className={`font-body transition-all delay-300 duration-300 absolute top-2 left-0 right-0 z-10 ${
                  status === 'success'
                    ? 'opacity-100 pointer-events-all'
                    : 'opacity-0 pointer-events-none'
                }`}
              >
                <motion.h2
                  className={`font-headline text-2xl`}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                >
                  Thank you we will be in touch.
                </motion.h2>
              </div>
              <div
                className={`grid gap-x-12 grid-cols-1 md:grid-cols-2 gap-y-6 font-body text-medium transition-all duration-300 ${
                  status === 'success' && 'opacity-0 pointer-events-none'
                }`}
              >
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="firstName">
                    My first name
                  </label>
                  <Field
                    type="text"
                    name="firstName"
                    className={inputClasses}
                    placeholder="James"
                  />
                  <ErrorMessage name="firstName" className={errorClasses} component="div" />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="lastName">
                    My last name
                  </label>
                  <Field type="text" name="lastName" className={inputClasses} placeholder="Smith" />
                  <ErrorMessage name="lastName" className={errorClasses} component="div" />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="email">
                    My email address
                  </label>
                  <Field
                    type="email"
                    name="email"
                    className={inputClasses}
                    placeholder="james.smith@email.com"
                  />
                  <ErrorMessage name="email" className={errorClasses} component="div" />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="phone">
                    My phone number
                  </label>
                  <Field
                    type="text"
                    name="phone"
                    className={inputClasses}
                    placeholder="+64 21 234 567"
                  />
                  <ErrorMessage name="phone" className={errorClasses} component="div" />
                </div>
                {/* <div className="col-span-1">
                  <label className={labelClasses} htmlFor="occupation">
                    My occupation
                  </label>
                  <Field
                    type="text"
                    name="occupation"
                    className={inputClasses}
                    placeholder="Civil Engineer"
                  />
                </div> */}
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="company">
                    Current employer
                  </label>
                  <Field
                    type="text"
                    name="company"
                    className={inputClasses}
                    placeholder="Company Name"
                  />
                  <ErrorMessage name="company" className={errorClasses} component="div" />
                </div>

                {/* NEW FIELDS 4/10/2021 */}

                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="currentCity">
                    Currently living in (City)
                  </label>
                  <Field
                    type="text"
                    name="currentCity"
                    className={inputClasses}
                    placeholder="Auckland"
                  />
                  <ErrorMessage name="currentCity" className={errorClasses} component="div" />
                </div>

                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="scarce">
                    My Occupation is within the Scarce Categories
                  </label>
                  <Field
                    as="select"
                    name="scarce"
                    className={selectClasses}
                    placeholder="New Zealand"
                  >
                    <option value="null">Select</option>
                    {yesNoUnsure.map((item) => (
                      <option className="text-charcoal" value={`${item}`}>
                        {item}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="scarce" className={errorClasses} component="div" />
                </div>

                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="positionTitle">
                    What was your Position Title on the 29th September 2021
                  </label>
                  <Field
                    type="text"
                    name="positionTitle"
                    className={inputClasses}
                    placeholder="Nurse"
                  />
                  <ErrorMessage name="positionTitle" className={errorClasses} component="div" />
                </div>

                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="payRate">
                    What was your Pay Rate on the 29th September 2021?
                  </label>
                  <Field
                    type="text"
                    name="payRate"
                    className={inputClasses}
                    placeholder="$27.00/hr"
                  />
                  <ErrorMessage name="payRate" className={errorClasses} component="div" />
                </div>

                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="arrivalDate">
                    What was your Arrival Date in New Zealand?
                  </label>
                  <Field
                    type="text"
                    name="arrivalDate"
                    className={inputClasses}
                    placeholder="24/01/2018"
                  />
                  <ErrorMessage name="arrivalDate" className={errorClasses} component="div" />
                </div>

                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="minimumTime">
                    Have you been in NZ for a minimum of 821 days since 21 September 2018
                  </label>
                  <Field as="select" name="minimumTime" className={selectClasses} placeholder="">
                    <option value="null">Select</option>
                    {yesNo.map((item) => (
                      <option className="text-charcoal" value={`${item}`}>
                        {item}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="minimumTime" className={errorClasses} component="div" />
                </div>

                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="visaHeld">
                    What Visa did you hold on the 29 September 2021
                  </label>
                  <Field as="select" name="visaHeld" className={selectClasses} placeholder="">
                    <option value="null">Select</option>
                    {visas.map((item) => (
                      <option className="text-charcoal" value={`${item}`}>
                        {item}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="visaHeld" className={errorClasses} component="div" />
                </div>

                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="visaExpiry">
                    Please enter your existing Visa expiry date
                  </label>
                  <Field
                    type="text"
                    name="visaExpiry"
                    className={inputClasses}
                    placeholder="24/01/2022"
                  />
                  <ErrorMessage name="visaExpiry" className={errorClasses} component="div" />
                </div>

                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="passportNationality">
                    Passport Nationality
                  </label>
                  <Field
                    as="select"
                    name="passportNationality"
                    className={selectClasses}
                    placeholder="New Zealand"
                  >
                    <option value="null">Select country</option>
                    {countryList.map((item) => (
                      <option className="text-charcoal" value={`${item}`}>
                        {item}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="passportNationality"
                    className={errorClasses}
                    component="div"
                  />
                </div>

                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="residencyType">
                    Residency Application Type
                  </label>
                  <Field as="select" name="residencyType" className={selectClasses} placeholder="">
                    <option value="null">Select</option>
                    {residencyTypes.map((item) => (
                      <option className="text-charcoal" value={`${item}`}>
                        {item}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="residencyType" className={errorClasses} component="div" />
                </div>

                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="contactMe">
                    I wish to be contacted to discuss the next steps in my application for Residency
                  </label>
                  <Field as="select" name="contactMe" className={selectClasses} placeholder="">
                    <option value="null">Select</option>
                    {yesNo.map((item) => (
                      <option className="text-charcoal" value={`${item}`}>
                        {item}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="contactMe" className={errorClasses} component="div" />
                </div>

                <div className="col-span-1">
                  <label className={`${labelClasses} invisible`} htmlFor="notes">
                    Notes
                  </label>
                  <Field
                    type="text"
                    name="notes"
                    className={inputClasses}
                    placeholder="Notes"
                    component="textarea"
                  />
                </div>

                {/* <div className="col-span-1">
                  <label className={labelClasses} htmlFor="countryOfResidence">
                    I am currently living in
                  </label>
                  <Field
                    as="select"
                    name="countryOfResidence"
                    className={selectClasses}
                    placeholder="New Zealand"
                  >
                    <option value="null">Select country</option>
                    {countryList.map((item) => (
                      <option value={`${item}`}>{item}</option>
                    ))}
                  </Field>
                </div>

                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="visaEnquiryType">
                    I am enquiring about
                  </label>
                  <Field
                    as="select"
                    name="visaEnquiryType"
                    className={selectClasses}
                    placeholder="Visa Type"
                  >
                    <option value="null">Visa Type</option>
                    <option value="Visa Prequal">Visa Prequal</option>
                    <option value="Company Accreditation">Company Accreditation</option>
                    <option value="Visa Application">Visa Application</option>
                  </Field>
                </div> */}

                <div className="col-span-1 lg:col-span-2 flex justify-end mt-4">
                  <button
                    className="no-underline py-2 border-b border-white font-display text-lg"
                    type="submit"
                  >
                    Submit Enquiry
                  </button>
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};
